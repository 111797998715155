/*====================== 404 page =======================*/
.page_404 {
    padding: 40px 0;
    background: #fff;
    font-family: "Arvo", serif;

}
.page_404 img {
    width: 100%;
}
.four_zero_four_bg {
    background-image: url('/assets/main/404.gif');
    height: 400px;
    background-position: center;
    background-repeat: no-repeat;
    background-position: center;
    background-clip: content-box;
    align-items: center;
    justify-content: center;
}
.link_404 {
    color: #fff !important;
    padding: 10px 20px;
    background: #39ac31;
    margin: 20px 0;
    display: inline-block;
}
.contant_box_404 {
    margin-top: -50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
