.ErrorBoundaryStyle_page_404__7Vc3D{padding:40px 0;background:#fff;font-family:"Arvo",serif}.ErrorBoundaryStyle_page_404__7Vc3D img{width:100%}.ErrorBoundaryStyle_four_zero_four_bg__l6_t6{background-image:url("/assets/main/404.gif");height:400px;background-position:center;background-repeat:no-repeat;background-position:center;background-clip:content-box;align-items:center;justify-content:center}.ErrorBoundaryStyle_link_404__oXbP7{color:#fff !important;padding:10px 20px;background:#39ac31;margin:20px 0;display:inline-block}.ErrorBoundaryStyle_contant_box_404__QCgA1{margin-top:-50px;display:flex;flex-direction:column;align-items:center;justify-content:center}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_583505';
  font-style: normal;
  font-weight: 400;
  font-display: optional;
  src: url(/_next/static/media/7c53f7419436e04b.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_583505';
  font-style: normal;
  font-weight: 400;
  font-display: optional;
  src: url(/_next/static/media/1c57ca6f5208a29b.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_583505';
  font-style: normal;
  font-weight: 400;
  font-display: optional;
  src: url(/_next/static/media/cff529cd86cc0276.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_583505';
  font-style: normal;
  font-weight: 400;
  font-display: optional;
  src: url(/_next/static/media/7be645d133f3ee22.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_583505';
  font-style: normal;
  font-weight: 400;
  font-display: optional;
  src: url(/_next/static/media/5647e4c23315a2d2.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_583505';
  font-style: normal;
  font-weight: 400;
  font-display: optional;
  src: url(/_next/static/media/3dbd163d3bb09d47.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_583505';
  font-style: normal;
  font-weight: 400;
  font-display: optional;
  src: url(/_next/static/media/934c4b7cb736f2a3.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Roboto_Fallback_583505';src: local("Arial");ascent-override: 92.49%;descent-override: 24.34%;line-gap-override: 0.00%;size-adjust: 100.30%
}.__className_583505 {font-family: '__Roboto_583505', '__Roboto_Fallback_583505';font-weight: 400;font-style: normal
}

